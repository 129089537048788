import {
	ComposableMap,
	Geographies,
	Geography,
	ZoomableGroup,
	Marker,
	Graticule
} from "react-simple-maps";
import React, { useEffect, useContext, useState, memo } from "react";
import ReactTooltip from "react-tooltip";
import './About.scss';

import geojson from './world-countries.json';

import MediaThumber from './MediaThumber';

import { AnchorButton } from "@blueprintjs/core";

const places_ive_been = [
	"US",
	"CA",
	"PR",
	"IE",
	"FR",
	"CH",
	"IT",
	"DE",
	"AT",
	"CZ",
	"HU",
	"DK",
	"TR",
	"JP",
	"CN",
	"TH",
	"VN",
	"KH",
];

const codeToFlag = (code) => String.fromCodePoint(...code.split('').map(c => 0x1f1a5 + c.charCodeAt()));

const About = () => {
	const [tooltipContent, setTooltipContent] = useState('');
	
	useEffect (() => {
		ReactTooltip.rebuild();
	}, [tooltipContent]);
	return (
		<div style={{position: 'relative'}}>
			<MediaThumber style={{marginLeft: '0.1em', marginBottom: '20px', float: 'right', width: '20%'}} image="/media/peek.png" />
			<p>
				Logan Dean is a {Math.floor((Date.now()-858704400000)/525600/60000)} year old software developer from Philadelphia.
				She graduated from Drexel University in 2022 CE, though she's been programming a lot longer. She has a bit of a passion
				for user interfaces, especially mobile and desktop applications, but she's been known to code all sorts of things.
				She made this website, for example.
			</p>
			<MediaThumber style={{marginRight: '1em', marginBottom: '10px', float: 'left', width: '30%'}} image="/media/photo.jpeg" />
			<p>
				Logan knows lots of programming langauges, but her favorite one is probably C. She also likes
				Awk, JavaScript, Lua, Objective-C, and Vala. She also knows plenty of languages that she doesn't like
				very much, including C++, Java, and Python.
			</p>
			<p>
				When Logan isn't programming, she wears many other hats. Beekeeping, goat herding, foraging, knifemaking, linguistics,
				D&D, hardware hacking, and Twitch streaming are among her favorite hobbies.
			</p>
			<p style={{clear: 'both'}}>
				Logan also likes to travel. Here's a map of all the countries she's visited. It also serves as proof that she knows how to make a map in JavaScript.
			</p>
			<ReactTooltip>{tooltipContent}</ReactTooltip>
			<ComposableMap
				className="map bp4-card bp4-elevation-2"
				projection="geoMercator"
				data-tip={tooltipContent}
			>
				<ZoomableGroup center={[40, 30]} zoom={1.6}
					onScroll={(e) => {
						e.preventDefault();
					}}>
					<Graticule stroke="#EAEAEC" />
					<Geographies geography={geojson}>
						{({ geographies }) =>
							geographies.map((geo) => (
								<Geography
									key={geo.rsmKey}
									geography={geo}
									stroke="#000"
									strokeWidth="0.5px"
									onMouseEnter={() => {
										setTooltipContent(codeToFlag(geo.properties["Alpha-2"]) + " " + geo.properties.name);
									}}
									onMouseLeave={() => {
										setTooltipContent("");
									}}
									style={{
										default: {
											fill: places_ive_been.includes(geo.properties["Alpha-2"]) ? "#5c5" : "#d6d6da",
											outline: "none"
										},
										hover: {
											fill: "#a23",
											outline: "none"
										},
										pressed: {
											fill: "#a23",
											outline: "none"
										}
									}}
								/>
							))
						}
					</Geographies>
					<Marker coordinates={[-75.13, 40.01]}>
						<circle r={2} fill="#e33" stroke="#fff" strokeWidth={0.4} />
						<polygon points="0,-1.8, 1.8,-0.5, 1.3,-0.5, 1.3,1.3, 0.5,1.3, 0.5,0, -0.5,0, -0.5,1.3, -1.3,1.3, -1.3,-0.5, -1.8,-0.5" fill="white"/>
					</Marker>
				</ZoomableGroup>
			</ComposableMap>
			<AnchorButton target="_blank" text="Logan's Résumé" style={{position: 'absolute', bottom: '0', right: '0'}} minimal href="/media/resume.pdf" icon="document" />
		</div>
	);
};

export default memo(About);
