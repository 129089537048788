import { Classes, Dialog, Icon } from "@blueprintjs/core";
import { useState } from 'react';

const MediaThumber = ({image, video, ...rest}) => {
	const [dialogOpen, setDialogOpen] = useState(false);
	return (
		<div style={{position: 'relative', float: 'left', width: '50%', marginRight: '1em'}} {...rest}>
			<img style={{width: '100%', cursor: video != undefined ? 'pointer' : 'zoom-in'}} src={image} onClick={() => setDialogOpen(true)}/>
			{video != undefined ? 
				(<div>
					<Icon style={{filter: 'blur(5px)', position: 'absolute', bottom: 20, right: 20}} color="#333" icon="video" size={53} />
					<Icon style={{position: 'absolute', bottom: 22, right: 22}} icon="video" size={50} />
				</div>)
				: null
			}
			<Dialog
				style={{background: 'transparent', width: '80%'}}
				canOutsideClickClose={true}
				canEscapeKeyClose={true}
				isOpen={dialogOpen}
				onClose={() => setDialogOpen(false)}
			>
				{ video != undefined ? 
				<video controls src={video} />
				: <img className={Classes.DIALOG_BODY} src={image}/>
				}
			</Dialog>
		</div>
	);
};

export default MediaThumber;
