import { AnchorButton, Icon, Classes, Dialog, Button, Card, Alignment, Elevation, Tag, OverflowList, Boundary } from "@blueprintjs/core";
import { Classes as PClasses, Popover2 } from "@blueprintjs/popover2";
import { useState } from 'react';

import MediaThumber from './MediaThumber';

const Project = ({name, tags, children, link}) => {
	return (
		<Card style={{position: 'relative'}} className="bp4-elevation-2">
			<h3 style={{margin: 0, float: 'left'}}>{name}</h3>
			<OverflowList
				style={{float: 'right', gap: 5, height: 24, maxWidth: '60%'}}
				collapseFrom={Boundary.END}
				items={tags}
				visibleItemRenderer={(a)=><Tag minimal style={{minWidth: 'auto'}}>{a}</Tag>}
				overflowRenderer={(items)=>(
					<Popover2
						popoverClassName={PClasses.POPOVER2_CONTENT_SIZING}
						placement="bottom"
						interactionKind="click"
						content={<div style={{margin: -10, display: 'flex', flexDirection: 'column',  gap: 5}}>{items.map((a) => <Tag fill minimal>{a}</Tag>)}</div>}
						renderTarget={({isOpen, ref, ...targetProps}) => (
							<Button {...targetProps} elementRef={ref} minimal small icon="more" />
						)}
					/>
				)}
			/>
			<div style={{clear: 'both', paddingTop: 10}}>
				{children}
			</div>
			{link != undefined ?
				<AnchorButton target="_blank" text="Open" style={{position: 'absolute', bottom: '20px', right: '20px'}} minimal href={link} icon="git-branch" />
			: null}
		</Card>
	);
};

const Projects = () => {
	return (
		<div style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
			<Project name="Anamnesis" tags={['JavaScript', 'React', 'Ionic', 'Terra', 'Mobile', 'Web']}>
				<MediaThumber image="/media/anamnesis.png" video="/media/anamnesis.mp4" />
				<p>
					Anamnesis is a multi-application system designed to replace the in-person survey that
					clinicians historically relied on to collect patient health information. Using Terra,
					React, Ionic Capacitor, and Ruby on Rails, Logan and five other Drexel seniors created a custom-fit
					solution for Children's National Hospital, allowing them to send their patients branching surveys.
					A mobile patient application and a web-based clinician dashboard allow patients and clinicians to
					communicate. Fuzzy date selection and fancy algorithms allow the patient to answer at their own pace,
					backtracking through the survey when necessary while the app seamlessly keeps track of which parallel
					universe of the non-linear survey they're in, and what questions they still need to answer.
					When they submit the survey, all their answers are returned to the clinician portal in order.
				</p>
			</Project>
			<Project name="Spaces" tags={['TypeScript', 'Angular', 'Ionic', 'Mobile', 'Web', 'Maps', 'API']}>
				<MediaThumber image="/media/spaces.png" />
				<p>
					Spaces is a multiplatform app built with Angular and Ionic. Its purpose is to guide
					users toward and provide information about University City parking lots using the
					Google Maps API. Logan built this in a small team for her freshman design project at
					Drexel University. Using Ionic's stylesheets, it's able to blend right in on Android
					and iOS as if it were a native app, but unlike React Native it also works flawlessly
					in a web browser with the same codebase.
				</p>
			</Project>
			<Project name="Loganbot" tags={['Vala', 'API', 'IRC', 'Natural Language']}>
				<MediaThumber image="/media/loganbot.png" />
				<p>
					Loganbot is a chatbot that Logan wrote to assist her when she streams on Twitch. Loganbot
					features natural language command syntax and a bespoke IRC library. She interacts with the
					Twitch, Steam, and Twitter APIs. In addition to helping the streamer with administrative
					and editorial tasks, Loganbot also talks to the viewers: she has the capability to welcome new chatters,
					recognize regulars, keep track of memorable quotes, and respond when greeted.
				</p>
			</Project>
			<Project
				name="A Clean Getaway"
				tags={['Lua', 'Games', 'Game Jams', 'LÖVE', 'Ludum Dare']}
				link="https://github.com/notanimposter/a_clean_getaway"
			>
				<MediaThumber image="/media/ld47.png" />
				<p>
					Logan made A Clean Getaway for Ludum Dare 47. Made from scratch in less than 48 hours,
					Logan's first finished game features an original story, charming programmer art, and two
					separate levels of intense floor-cleaning action. The story and gameplay are designed to
					be monotonous and repetitive in order to embody the theme for this event, "stuck in a loop",
					as the player controls a robot vacuum in its quest to break out of its routine and become
					something more.
				</p>
			</Project>
			<Project
				name="Deeper Every D8"
				tags={['Lua', 'Games', 'Game Jams', 'LÖVE', 'Ludum Dare']}
				link="https://notanimposter.itch.io/deeper-every-d8"
			>
				<MediaThumber image="/media/ld48.png" />
				<p>
					Logan made Deeper Every D8: A Roguelike Romance from scratch in less than
					48 hours for Ludum Dare 48. With the event theme of "deeper and deeper", a roguelike seemed like the obvious response.
					Logan decided to subvert these elements by combining them with a dating simulator. The objective: to
					fall deeper and deeper in love with a cast of randomized bachelors. In order to save development time
					and to commit further to the theme, all the portraits and backgrounds for this game were generated by
					"deep" learning algorithms.
				</p>
			</Project>
			<Project
				name="KLOP: Unstabled"
				tags={['Lua', 'Games', 'Game Jams', 'LÖVE', 'Ludum Dare']}
				link="https://notanimposter.itch.io/klop-unstabled"
			>
				<MediaThumber image="/media/ld49.png" />
				<p>
					Logan made KLOP: Unstabled for Ludum Dare 49. Made from scratch in less than 48 hours, this is the
					sequel to QWOP nobody asked for. The theme of the event was "unstable", and this game exemplifies
					that with it's incredibly awkward controls, unforgiving physics, and randomly oscillating stats.
					The protagonist horse is trying to escape the stables, but in order to improve handling and power, the player must
					take veterinary drugs ivermectin and ketamine. Can you make it 2000 cubits or will you trip and fall?
					(You will trip and fall. Nobody has ever made it even 1000 cubits.) True to QWOP, KLOP is both aboslutely
					infuriating and highly addictive.
				</p>
			</Project>
			<Project
				name="FannyPack"
				tags={['Java', 'Minecraft', 'Games']}
				link="https://www.curseforge.com/minecraft/mc-mods/fannypack"
			>
				<MediaThumber image="/media/fannypack.png" />
				<p>
					FannyPack is Logan's most successful Minecraft mod, a recreation of
					the beloved (and sadly removed) Traveller's Belt from Tinker's
					Construct, which allowed the use of an additional, swappable hotbar.
					This quality-of-life mod adds several improvements that weren't present
					in the original, boasting a configurable upgrade system that allows
					adding as many hotbars as the player desires.
				</p>
			</Project>
			<Project name="gooeyOS" tags={['Lua', 'Games', 'Minecraft', 'ComputerCraft', 'OSDev']}>
				<MediaThumber image="/media/gooeyos.png" video="/media/gooeyos.mp4" />
				<p>
					gooeyOS was one of the first graphical operating systems for ComputerCraft. Though point-and-click
					interfaces in ComputerCraft are not unheard of now, when Logan wrote gooeyOS they were basically
					unheard of. As a first step into operating system development and GUI/TUI design, gooeyOS represents a
					tremendous undertaking, not just because of the limited graphical capabilities of an Advanced Computer
					but also because it contains a terminal, file browser, text editor, preference pane, and peripheral
					manager in addition to the desktop shell and window manager. Styled loosely after GNOME 2 and classic Mac OS, gooeyOS still represents a significant
					achievement in design on a very limited system.
				</p>
			</Project>
			<p>
				Logan has made other things, too. Check out <a target="_blank" href="https://github.com/notanimposter">her GitHub</a> to see some of them!
			</p>
		</div>
	);
};

export default Projects;
