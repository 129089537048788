import { Card, Elevation } from "@blueprintjs/core";

const Page = ({children}) => {
	return (
		<Card elevation={Elevation.THREE}>
			{children}
		</Card>
	);
};

export default Page;
