import './App.scss';
import { Icon, Classes, Card, Button, Switch, Navbar, Alignment, Elevation } from "@blueprintjs/core";
import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import * as Scroll from 'react-scroll';
import { Element, scroller } from 'react-scroll';


import Page from './Page';
import About from './About';
import Projects from './Projects';
import Contact from './Contact';

var ScrollButton = Scroll.ScrollLink(Button);

const App = () => {
	const systemPrefersDark = useMediaQuery(
		{query: "(prefers-color-scheme: dark)"},
		undefined,
		prefersDark => setIsDark(prefersDark)
	);
	const [isDark, setIsDark] = useState(systemPrefersDark);
	return (
		<div
			id="app"
			className={'app'+ (isDark ? ' bp4-dark' : ' bp4-light')}
			onScroll={(e) => {

				console.log(e);
			}}
		>
			<Navbar id="nav" className={Classes.ELEVATION_3}>
				<Navbar.Group align={Alignment.LEFT}>
					<Navbar.Heading className="bp4-monospace-text">Logan Dean</Navbar.Heading>
					<Navbar.Divider />
					<ScrollButton offset={-20} activeClass="bp4-active" smooth={true} spy={true} to="about"    containerId="app" className="bp4-minimal" icon="mugshot" text="About" />
					<ScrollButton offset={-20} activeClass="bp4-active" smooth={true} spy={true} to="projects" containerId="app" className="bp4-minimal" icon="build" text="Projects" />
					<ScrollButton offset={-20} activeClass="bp4-active" smooth={true} spy={true} to="contact"  containerId="app" className="bp4-minimal" icon="envelope" text="Contact" />
				</Navbar.Group>
				<Navbar.Group align={Alignment.RIGHT}>
					<Icon icon="moon" />
					<Switch
						large={true}
						style={{marginBottom: 0, marginLeft: 10, marginRight: 0}}
						checked={!isDark}
						onChange={({target: {checked}}) => setIsDark(!checked)}
					/>
					<Icon icon="flash" />
				</Navbar.Group>
			</Navbar>
			<div id="cards" className="cards">
				<Element name="about" className="page-wrap">
					<Card className="navbarSpacer"/>
					<Page>
						<About />
					</Page>
				</Element>
				<Element name="projects" className="page-wrap">
					<Card className="navbarSpacer"/>
					<Page>
						<Projects />
					</Page>
				</Element>
				<Element name="contact" className="page-wrap">
					<Card className="navbarSpacer"/>
					<Page>
						<Contact />
					</Page>
				</Element>
			</div>
		</div>
	);
};

export default App;
