
const Contact = () => {
	return (
		<div>
		<p>
			Please contact Logan via smoke signal or e-mail at <a href="mailto:me+pwebsite@lrdean.com">me@lrdean.com</a>.
		</p><p>
			If you would prefer her home address or phone number, you could always guess, but that might take a while. It might be easier to ask.
		</p>
		</div>
	);
};

export default Contact;
